import { Alert, Button, InputAdornment, TextField, Typography } from "@mui/material"
import { yellow } from "@mui/material/colors"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { BiCalculator, BiError, BiInfoCircle, BiLock } from "react-icons/bi"
import { ScheduledBreak } from "../../graphql/generated/client-types-and-hooks"
import { useModalProps } from "../../hooks/useModalProps"
import { PickPlus } from "../../types/helpers"
import { Schedule, ScheduleDetails } from "../Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { ManHoursCalculator } from "./ManHoursCalculatorModal"
import { WorkDaysAndDates } from "./WorkDaysAndDates"

export type GoogleCalendarHoliday = {
  created: string | Date
  description: string
  end: { date: string | Date }
  htmlLink: string
  iCalUID: string
  id: string
  start: { date: string | Date }
  status: string
  summary: string
  transparency: string
  updated: string | Date
  visibility: string
}

export type Holiday = PickPlus<GoogleCalendarHoliday, "id" | "summary" | "start">

type ScheduleBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name"
> & { breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name"> }

type ManHours = { jobTitle: string; numberOfWorkers: number | null; daysOnProject: number | null }[]

export const TaskScheduleForm: FC<{
  schedule?: Schedule
  scheduledBreaks?: ScheduleBreak[]
  showManHours?: boolean
  onEdit?: () => void
}> = ({ schedule, scheduledBreaks, showManHours = false, onEdit }) => {
  const [{ value }, _meta, manHoursHelpers] = useField("estimatedHours")
  const [{ value: workDaysValue }] = useField("workDays")
  const [manHoursFormData, setManHoursFormData] = useState<ManHours | undefined>()

  const [prevWorkDaysValue, setPrevWorkDaysValue] = useState(workDaysValue)
  const [hasProjectWorkDaysChanged, setHasProjectWorkDaysChanged] = useState(false)

  const modalProps = useModalProps("Man-Hours Calculator")

  useEffect(() => {
    if (!schedule || !manHoursFormData?.length) {
      return
    }

    const workHours = schedule?.workHours?.hours
    const totalManHours = manHoursFormData.reduce(
      (acc, curr) => acc + (curr.numberOfWorkers || 0) * (curr.daysOnProject || 0) * (workHours || 0),
      0
    )

    if (workHours && totalManHours) {
      manHoursHelpers.setValue(totalManHours.toString())
    }

    if (Boolean(prevWorkDaysValue) && workDaysValue !== prevWorkDaysValue) {
      // workDaysValue has changed since manHoursFormData was last updated
      setHasProjectWorkDaysChanged(true)
    }

    setPrevWorkDaysValue(workDaysValue)
  }, [schedule, manHoursHelpers, manHoursFormData, workDaysValue, prevWorkDaysValue])

  const onChangeManHours = (manHours: ManHours | undefined) => {
    setManHoursFormData(manHours)
    setPrevWorkDaysValue(workDaysValue)
    setHasProjectWorkDaysChanged(false)
  }

  return (
    <div>
      <section>
        <div className="flex justify-between border-b">
          <Typography variant="h5" fontSize={16}>
            Day and Time Settings
          </Typography>
          <Button variant="text" color="primary" onClick={onEdit}>
            Edit
          </Button>
        </div>
        <div className="flex flex-col gap-2 py-4">
          {(!schedule || !schedule.workHours?.hours) && (
            <Alert severity="warning" iconMapping={{ warning: <BiInfoCircle /> }} sx={{ marginBottom: 2 }}>
              Schedule with work hours is required for calculating man-hours
            </Alert>
          )}
          <ScheduleDetails schedule={schedule} scheduledBreaks={scheduledBreaks} />
        </div>
      </section>

      <section className="flex-col gap-x-2 py-4">
        <div className="flex border-b mb-8">
          <Typography variant="h5" fontSize={16}>
            Work Days & Man-Hours
          </Typography>
        </div>
        <div className="flex gap-x-2 pb-4">
          <WorkDaysAndDates schedule={schedule} />
        </div>
        {showManHours && (
          <div className="flex gap-x-2 py-4">
            <TextField
              name="manHours"
              type="number"
              label="Man-Hours"
              value={value}
              InputLabelProps={{ shrink: Boolean(value) }}
              InputProps={{
                endAdornment: manHoursFormData?.length && (
                  <InputAdornment position="end">
                    {!!manHoursFormData?.length && hasProjectWorkDaysChanged && (
                      <BiError color={yellow[800]} size={18} />
                    )}
                    <BiLock />
                  </InputAdornment>
                ),
              }}
              disabled={!!manHoursFormData?.length}
              onChange={(e) => manHoursHelpers.setValue(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<BiCalculator />}
              onClick={modalProps.handleOpenModal}
              sx={{ width: 300 }}
            >
              Calculate Man-Hours
            </Button>
          </div>
        )}
      </section>

      {modalProps.isOpen && (
        <ManHoursCalculator
          defaultValues={manHoursFormData ? { manHours: manHoursFormData } : undefined}
          modalProps={modalProps}
          hoursWorkingPerDay={schedule?.workHours?.hours}
          numberOfProjectDays={workDaysValue}
          onSubmit={onChangeManHours}
        />
      )}
    </div>
  )
}
