import { Box, MenuItem, Popover, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext, useState } from "react"
import { SwatchesPicker } from "react-color"
import * as Yup from "yup"
import {
  DeliverableUnit,
  useCreateDeliverableUnitMutation,
  useEditDeliverableUnitMutation,
} from "../../graphql/generated/client-types-and-hooks"
import { colors, randomColor } from "../../helpers/colors"
import { OrganizationSettingsContext } from "../../providers/OrganizationSettingsProvider"
import { PickPlus } from "../../types/helpers"
import { ButtonFilled } from "../Elements/ButtonFilled"
import { TextField } from "../Formik/TextField"
import { MuiModal } from "../Modals/MuiModal"
import { errorSnack, successSnack } from "../Notistack/ThemedSnackbars"

type Props = {
  handleCloseModal: () => void
  initialValues?: Partial<Values>
  isOpen: boolean
  onSuccess?: (data: Partial<Values>) => void
}
interface ColorResult {
  hex: string
}
type Values = PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure" | "color">

export const AddOrEditUnitModal: FC<Props> = ({ isOpen = false, initialValues = {}, handleCloseModal, onSuccess }) => {
  const { unitsOfMeasure } = useContext(OrganizationSettingsContext)

  const [, createDeliverableMutation] = useCreateDeliverableUnitMutation()
  const [, editDeliverableMutation] = useEditDeliverableUnitMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const color = getRandomColor()

  const handleColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleColorClose = () => {
    setAnchorEl(null)
  }

  const onSubmit = async (values: Values, { resetForm }: { resetForm: () => void }) => {
    if (!values.id) {
      createDeliverableMutation(values).then((result) => {
        if (result.error) {
          errorSnack("Failed to create unit")
        } else {
          successSnack("Unit saved successfully.")
          onSuccess?.(result.data?.createDeliverableUnit!)

          handleCloseModal()
          resetForm()
        }
      })
    } else {
      editDeliverableMutation({ ...values, id: values.id }).then((result) => {
        if (result.error) {
          errorSnack("Failed to edit unit")
        } else {
          successSnack("Unit saved successfully.")
          onSuccess?.(result.data?.editDeliverableUnit!)
          resetForm()
          handleCloseModal()
        }
      })
    }
  }

  return (
    <Formik
      initialValues={{
        id: "",
        description: "",
        referenceNumber: "",
        unitOfMeasure: "",
        color,
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        description: Yup.string().required().min(3),
        referenceNumber: Yup.string(),
        unitOfMeasure: Yup.string()
          .oneOf(unitsOfMeasure.map((u) => u.name))
          .required(),
      })}
      onSubmit={onSubmit}
    >
      {({ submitForm, resetForm, values, setFieldValue }) => {
        const handleColorChange = (color: ColorResult) => {
          setFieldValue("color", color.hex)
        }
        return (
          <MuiModal
            contentLabel={values.id ? "Edit Unit" : "Add Unit"}
            submitButtonText="Save"
            isOpen={isOpen}
            handleCloseModal={() => {
              resetForm()
              handleCloseModal()
            }}
            submitForm={submitForm}
          >
            <Form className="h-full">
              <div className="flex flex-col gap-5 min-w-[280px]">
                <TextField fullWidth name="description" label="Unit Description" required />
                <TextField fullWidth name="referenceNumber" label="Reference Number" />
                <TextField fullWidth label="Unit of Measure" name="unitOfMeasure" required select size="small">
                  {unitsOfMeasure.map((uom) => (
                    <MenuItem key={uom.name} value={uom.name}>
                      {uom.name} ({uom.symbol})
                    </MenuItem>
                  ))}
                </TextField>
                <Box
                  sx={{
                    border: `2px dashed ${colors.gray[400]}`,
                    width: "100%",
                    backgroundColor: `${colors.gray[25]}`,
                    padding: "20px",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="flex flex-row items-center gap-6">
                    <div style={{ backgroundColor: values.color }} className="w-10 h-10 rounded-full"></div>
                    <Typography color={colors.neutral[400]}>Select a unit color</Typography>
                  </div>

                  <ButtonFilled color="black" onClick={handleColorClick} type="button">
                    Select color
                  </ButtonFilled>

                  <Popover
                    id={anchorEl ? "simple-popover" : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleColorClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    <SwatchesPicker color={values.color} onChangeComplete={handleColorChange} />
                  </Popover>
                </Box>
              </div>
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}

const getRandomColor = () => {
  if (randomColor.length === 0) return colors.neutral[200]
  const randomIndex = Math.floor(Math.random() * randomColor.length)
  return randomColor[randomIndex]
}
