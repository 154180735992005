import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { Form, Formik } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { CombinedError, useQuery } from "urql"
import * as Yup from "yup"
import {
  DeliverableUnit,
  Maybe,
  ScheduleTemplate,
  ScheduledBreak,
  Task,
  UnitGoal,
  WorkersCompCode,
  useCreateTaskGroupMutation,
  useScheduleWithBreaksQuery,
  useTaskCreateMutation,
  useTaskEditMutation,
  useUpdateTaskGroupMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { DevelopmentFeatureFlagContext } from "../../../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { MetadataNote } from "../../../types/MetadataNote"
import { PickPlus } from "../../../types/helpers"
import { DropDownMUI, DropDownMUIItem } from "../../DropDownMUI"
import { FormRow } from "../../FormRow"
import { DatePicker } from "../../Formik/DatePicker"
import { MetadataNoteListInput } from "../../Formik/MetadataNoteInput"
import { StandardInput } from "../../Formik/StandardInput"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { MultiUnitInput } from "../../MultiUnitInput"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { RenderIf } from "../../RenderIf"
import { TaskScheduleForm } from "../../Scheduling/TaskScheduleForm"
import { AddOrEditScheduleTemplateDrawer } from "../Drawer/AddOrEditScheduleTemplateDrawer"
import { DrawerFooter } from "../Drawer/DrawerFooter"

export const ProjectTasksGroupAndUnits = graphql(`
  query GetTasksGroupsAndUnits($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      name
      projectId
      archived
      dateCreated
      startDate
      endDate
      estimatedHours
      isDefault
      isComplete
      description
      hasReportableUnit
    }

    taskGroups(projectId: $projectId) {
      assetCount
      completedTaskCount
      createdAt
      description
      id
      name
      notes
      organizationId
      unitProgress
      unitTargetGoal
      taskCount
      updatedAt
      userCount
    }

    deliverableUnits {
      id
      description
      unitOfMeasure
      referenceNumber
      createdAt
      deletedAt
    }
  }
`)

type UnitGoalInput = {
  deliverableUnitId?: string
  targetQuantity?: number
  isPrimary: boolean
}

type Values = {
  metadata: MetadataNote[]
  name: string
  taskType: string

  additionalUnitGoals?: UnitGoalInput[]
  description?: Maybe<string>
  endDate?: Maybe<Date | undefined>
  estimatedHours?: Maybe<number> | ""
  manHours?: Maybe<number> | ""
  groupId?: Maybe<string | undefined>
  primaryUnitGoals?: UnitGoalInput[]
  startDate?: Maybe<Date | undefined>
  workersCompCodeId?: Maybe<string>
}

type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
}

type Props = {
  task?: Partial<
    PickPlus<
      Task,
      "id" | "description" | "endDate" | "estimatedHours" | "groupId" | "metadata" | "name" | "projectId" | "startDate"
    >
  > & {
    unitGoals?: UnitGoalExpectation[]
    workersCompCode?: PickPlus<WorkersCompCode, "id" | "name"> | null
  }
  isTaskGroup?: boolean
  group?: { id: string; name: string }
  onCancel: () => void
  onSuccess?: (arg0?: any) => void
  onError?: () => void
}

type ScheduleBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name" | "breakTaskId"
> & { breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name"> }

const emptyNote = { content: "", label: "" }

const validationSchema = Yup.object().shape({
  taskType: Yup.string(),
  name: Yup.string().trim().required("Required").label("Task name"),
  groupId: Yup.string()
    .nullable()
    .when("taskType", {
      is: "sub-task",
      then: (schema) => schema.required("Task is required"),
    }),
  primaryUnitGoals: Yup.array(
    Yup.object()
      .nullable()
      .shape(
        {
          deliverableUnitId: Yup.string().when("targetQuantity", {
            is: (exist: number) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.nullable(),
          }),
          targetQuantity: Yup.number().when("deliverableUnitId", {
            is: (exist: string) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema,
          }),
        },
        [["deliverableUnitId", "targetQuantity"]]
      )
  ),
  additionalUnitGoals: Yup.array(
    Yup.object().shape({
      deliverableUnitId: Yup.string().when("targetQuantity", {
        is: (exist: number) => !!exist,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
      targetQuantity: Yup.number(),
    })
  ),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable().min(Yup.ref("startDate"), "Due date must be after Start date"),
  description: Yup.string().nullable(),
  estimatedHours: Yup.number().nullable(),
  workersCompCodeId: Yup.string().nullable(),
  metadata: Yup.array(
    Yup.object().shape({
      content: Yup.string().trim(),
      label: Yup.string().trim(),
    })
  ),
})

export const CreateOrEditTaskForm: FC<Props> = ({ task, onCancel, onSuccess, onError, isTaskGroup = false, group }) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  const [{ data: scheduleAndBreaksData, error: scheduleAndBreaksError }] = useScheduleWithBreaksQuery({
    variables: { projectId: task?.projectId!, taskId: task?.id || "" },
  })

  const [{ error: editError }, updateTask] = useTaskEditMutation()
  const [{ error: createError }, createTask] = useTaskCreateMutation()
  const [{ error: createTaskGroupError }, createTaskGroup] = useCreateTaskGroupMutation()
  const [{ error: updateTaskGroupError }, updateTaskGroup] = useUpdateTaskGroupMutation()

  const isCreationNotEdit = !task?.id

  const [{ data, fetching, error }] = useQuery({
    query: ProjectTasksGroupAndUnits,
    variables: { projectId: task?.projectId! },
    pause: !task?.projectId,
  })

  useHandleError(scheduleAndBreaksError, "There was an error retrieving schedule details.")

  useHandleError(editError, "There was an error saving the task details.")
  useHandleError(createError, "There was an error saving the task details.")
  useHandleError(createTaskGroupError, "There was an error saving the task group details.")
  useHandleError(updateTaskGroupError, "There was an error saving the task group details.")

  useHandleError(error, "There was an error loading the project tasks")

  const handleError = (error: CombinedError | undefined, message: string) => {
    errorSnack(message)
    console.error(error)
    onError?.()
  }

  const handleSuccess = (message: string) => {
    if (message) {
      successSnack(message)
    }
    onSuccess?.()
  }

  const possibleTaskGroups: DropDownMUIItem[] = (data?.taskGroups || [])
    .filter((tg) => tg.completedTaskCount === 0 || tg.completedTaskCount !== tg.taskCount)
    .map((tg) => ({
      value: tg.id,
      label: tg.name,
    }))

  const [taskSchedule, setTaskSchedule] = useState<ScheduleTemplate | undefined>(
    scheduleAndBreaksData?.scheduleTemplates[0] || undefined
  )
  const [taskScheduledBreaks, setTaskScheduledBreaks] = useState<ScheduleBreak[] | undefined>(
    scheduleAndBreaksData?.scheduledBreaks || undefined
  )
  const [hasEditedSchedule, setHasEditedSchedule] = useState(false)

  useEffect(() => {
    if (!taskSchedule && scheduleAndBreaksData?.scheduleTemplates?.length) {
      setTaskSchedule(scheduleAndBreaksData.scheduleTemplates[0])
    }

    if (!taskScheduledBreaks && scheduleAndBreaksData?.scheduledBreaks?.length) {
      setTaskScheduledBreaks(scheduleAndBreaksData.scheduledBreaks)
    }
  }, [scheduleAndBreaksData, setTaskSchedule, setTaskScheduledBreaks, taskSchedule, taskScheduledBreaks])

  const onSaveSchedule = (schedule: ScheduleTemplate, scheduledBreaks: ScheduleBreak[]) => {
    setTaskSchedule(schedule)
    setTaskScheduledBreaks(scheduledBreaks)
    setHasEditedSchedule(true)
  }

  const handleEditSchedule = () =>
    pushDrawer(
      <AddOrEditScheduleTemplateDrawer
        handleSave={onSaveSchedule}
        schedule={taskSchedule}
        scheduledBreaks={taskScheduledBreaks}
      />
    )

  const onSubmitCreate = async (values: Values) => {
    if (values.taskType === "summary-task") {
      createTaskGroup({
        name: values.name,
        projectId: task?.projectId!,
        description: values.description,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the summary task.")
        }

        return handleSuccess("Successfully created the summary task.")
      })
    } else {
      const scheduledBreaks = hasEditedSchedule
        ? taskScheduledBreaks
            ?.filter((sb) => sb.isActive)
            .map((scheduledBreak) => {
              const { id, breakTask, breakTaskId, ...scheduleBreak } = scheduledBreak

              return {
                ...scheduleBreak,
                breakTaskId: breakTaskId || breakTask?.id,
              }
            })
        : undefined

      const schedule = hasEditedSchedule ? { ...taskSchedule, id: undefined } : undefined

      // Formik form validation for arrays of unknown size requires the array item keys to at least exist.
      // Therefore, conditional array items may be expressed with undefined values.
      // Here we will remove those values.
      const unitGoals = [...(values.primaryUnitGoals || []), ...(values.additionalUnitGoals || [])].filter(
        ({ deliverableUnitId }) => deliverableUnitId
      )

      const metadata = values.metadata.filter(({ label, content }) => !!label || !!content)

      createTask({
        name: values.name,
        description: values.description || undefined,
        groupId: values.groupId || undefined,
        projectId: task?.projectId!,
        workersCompCodeId: values.workersCompCodeId || undefined,
        unitGoals: unitGoals.map((unitGoal) => ({
          ...unitGoal,
          deliverableUnitId: unitGoal.deliverableUnitId!,
          targetQuantity: unitGoal.targetQuantity || 0,
        })),
        endDate: values?.endDate || undefined,
        startDate: values?.startDate || undefined,
        estimatedHours: values?.estimatedHours ? +values?.estimatedHours : undefined,
        metadata,
        scheduledBreaks,
        schedule,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the task details.")
        }

        handleSuccess("Successfully created the task.")
      })
    }
  }

  const onSubmit = (values: Values) => {
    if (isCreationNotEdit) {
      onSubmitCreate(values)
    } else {
      onSubmitEdit(values)
    }
  }

  const onSubmitEdit = async (values: Values) => {
    if (values.taskType !== "sub-task") {
      values.groupId = null
    }

    const scheduledBreaks = hasEditedSchedule
      ? taskScheduledBreaks?.map((scheduledBreak) => {
          const { id, breakTask, breakTaskId, ...scheduleBreak } = scheduledBreak

          // if the task id exists we're editing an existing task schedule, keep the ID
          // if not, we're cloning a project or org schedule, strip the ID and the api will create a scheduleBreak for this task
          return {
            ...scheduleBreak,
            id: task?.id ? id : undefined,
            breakTaskId: breakTaskId || breakTask?.id,
          }
        })
      : undefined

    // if the task id exists we're editing an existing task schedule, keep the ID
    // if not, then we could be cloning a project or org schedule, strip the ID and the api will create a schedule for this task
    const schedule = hasEditedSchedule ? { ...taskSchedule, id: task?.id ? taskSchedule?.id : undefined } : undefined

    if (values.taskType !== "summary-task") {
      updateTask({
        id: task?.id!,
        projectId: task?.projectId!,
        ...values,
        schedule,
        scheduledBreaks,
        estimatedHours: values?.estimatedHours ? +values?.estimatedHours : undefined,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the task details.")
        }
        return handleSuccess("Updated the task details.")
      })
    } else {
      updateTaskGroup({
        id: task?.id!,
        ...values,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the summary task details.")
        }
        handleSuccess("Updated summary task details.")
      })
    }
  }

  let initialTaskType = "task"
  let selectedGroupId = ""

  if (isTaskGroup) initialTaskType = "summary-task"

  if (group?.id || task?.groupId) {
    initialTaskType = "sub-task"
    selectedGroupId = group?.id || task?.groupId!
  }

  const unitGoalInputs = (task?.unitGoals || []).map(({ deliverableUnit, isPrimary, targetQuantity }) => ({
    deliverableUnitId: deliverableUnit.id,
    isPrimary,
    targetQuantity: targetQuantity || 0,
  }))

  const initialValues: Values = isCreationNotEdit
    ? {
        additionalUnitGoals: [{ isPrimary: false, deliverableUnitId: undefined, targetQuantity: undefined }],
        description: "",
        estimatedHours: "",
        groupId: selectedGroupId,
        metadata: [emptyNote],
        name: "",
        primaryUnitGoals: [{ isPrimary: true, deliverableUnitId: undefined, targetQuantity: undefined }],
        taskType: initialTaskType,
        workersCompCodeId: "",
      }
    : {
        estimatedHours: "",
        workersCompCodeId: "",
        ...task,
        additionalUnitGoals: unitGoalInputs.filter(({ isPrimary }) => !isPrimary),
        description: task.description || "",
        groupId: selectedGroupId,
        metadata: task.metadata || [emptyNote],
        name: task.name || "",
        primaryUnitGoals: unitGoalInputs.filter(({ isPrimary }) => isPrimary),
        taskType: initialTaskType,
      }

  if (fetching) {
    return <Skeleton />
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue, handleChange }) => {
        return (
          <Form>
            {isCreationNotEdit && (
              <Typography fontSize="36px" lineHeight="40px" fontWeight={700} marginBottom={5}>
                New Task
              </Typography>
            )}

            <div className="w-75">
              <div className="flex justify-between mt-8 md:col-span-12 border-b">
                <Typography variant="h5" fontSize={18}>
                  Task Type
                </Typography>
              </div>

              <div className="flex py-2">
                {!isTaskGroup && (
                  <RadioGroup
                    row
                    value={values.taskType}
                    name="taskType"
                    onChange={(e) => {
                      const type = e.currentTarget.value
                      setFieldValue("taskType", type)

                      if (type !== "sub-task") {
                        setFieldValue("groupId", undefined)
                      }
                    }}
                  >
                    <FormControlLabel value="task" control={<Radio />} label="Task" name="taskType" />
                    <FormControlLabel value="sub-task" control={<Radio />} label="Sub-task" name="taskType" />
                    {isCreationNotEdit && (
                      <FormControlLabel value="summary-task" control={<Radio />} label="Summary Task" name="taskType" />
                    )}
                  </RadioGroup>
                )}
              </div>

              <div>
                <Typography color={grey[400]} fontSize={14}>
                  A task is a single checklist item that can have units, users, and assets applied to it.
                </Typography>
              </div>

              <div className="flex justify-between mt-8 md:col-span-12 border-b">
                <Typography variant="h5" fontSize={18}>
                  Basic Info
                </Typography>
              </div>

              <div className="w-2/3 grid gap-y-4">
                <div className="grid gap-y-4">
                  <TextField
                    id="task-name"
                    name="name"
                    label="Task name"
                    value={values.name}
                    onChange={handleChange}
                    required
                    autoFocus
                    fullWidth
                  />
                  {values.taskType === "sub-task" && data?.tasks?.length && (
                    <DropDownMUI items={possibleTaskGroups} fieldName="groupId" label="Summary task" />
                  )}
                </div>

                {values.taskType !== "summary-task" && (
                  <>
                    {!flagIsEnabled("Org Scheduling") && (
                      <>
                        <div className="flex flex-col md:flex-row grow gap-x-1 gap-y-2 md:gap-y-0">
                          <DatePicker id="start-date" name="startDate" label="Start Date" />
                          <DatePicker id="end-date" name="endDate" label="End Date" />
                        </div>
                        <StandardInput
                          id="man-hours"
                          label=""
                          name="estimatedHours"
                          placeholder="Enter man-hours"
                          type="number"
                          containerClassName="grow"
                          labelClassName="hidden"
                        />
                      </>
                    )}

                    <RenderIf permissionsInclude={"timeEntry:export"}>
                      <WorkersCompCodeSelect name="workersCompCodeId" />
                    </RenderIf>
                  </>
                )}

                <TextareaAutosize
                  onChange={handleChange}
                  name="description"
                  placeholder="Task description"
                  minRows={4}
                />
              </div>

              {flagIsEnabled("Org Scheduling") && (
                <div className="border px-8 mt-8">
                  <div className="flex justify-between mt-8 md:col-span-12">
                    <Typography variant="h5" fontSize={18}>
                      Schedule
                    </Typography>
                  </div>

                  <div className="grid gap-y-4">
                    <TaskScheduleForm
                      schedule={taskSchedule}
                      scheduledBreaks={taskScheduledBreaks}
                      onEdit={handleEditSchedule}
                      showManHours
                    />
                  </div>
                </div>
              )}

              <div>
                <Typography fontSize={20} fontWeight={700} marginTop={5}>
                  Task Notes
                </Typography>
                <hr className="stroke-black mt-2 mb-4" />

                <FormRow textLabelClassName="mt-6 md:mt-8" textLabel="Notes" childrenContainerClassName="pt-4">
                  <MetadataNoteListInput values={values.metadata} />
                </FormRow>
              </div>

              {values.taskType !== "summary-task" && (
                <div className="pb-4">
                  <MultiUnitInput estimatedHours={values.estimatedHours} name="primaryUnitGoals" isPrimary={true} />
                  <MultiUnitInput name="additionalUnitGoals" isPrimary={false} />
                </div>
              )}

              <DrawerFooter>
                <div className="col-span-12 flex justify-start flex-col md:flex-row gap-4 md:gap-6 md:max-w-md ">
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting} size="large">
                    Save task
                  </Button>
                  <Button variant="text" type="button" onClick={() => onCancel()} size="large">
                    Cancel
                  </Button>
                </div>
              </DrawerFooter>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
